import React, { useState, useEffect } from "react";
import PlainPage from "../../templates/plain-page";
import { firebaseFunc } from "../../components/firebase/firestore";
import {
  formatDate,
  convertHashtagsToURLFormat,
  copyToClipboard,
} from "../../utils/utils";
import { Link, withPrefix } from "gatsby";

const ASK_FOR_INVITE_TEXT =
  "Guys, I am really looking to join the @onOccasio African network. If you or someone you know is willing to verify me, please send me an invite.";

const ASK_FOR_INVITE_TEXT_W_HASHTAGS =
  "Guys, I am really looking to join the @onOccasio African network. If you or someone you know is willing to verify me, please send me an invite. #onOCCASIO #onOCCASIOInvite #onOCCASIOReferral";

const Content = ({ currentUser, location }) => {
  // useEffect(() => {
  //   firebaseFunc.httpsCallable("deleteMe")();
  // }, []);

  return (
    <div className="column is-10 is-offset-1">
      <h2 className="title is-size-3 has-text-weight-bold is-bold-light">
        Get Invited
      </h2>
      <p className="has-text-justified">
        OCCASIO is changing the way business gets done in Africa. It is a
        trusted network of professionals, businesses, entrepreneurs and
        investors connected with a view to create economic opportunities. The
        network is currently accepting a limited number of members, so if you
        have not already joined or received an invite, you can be patient and
        wait for an invitation code to arrive. Alternatively you can take
        matters into your hands and follow the three steps below to speed things
        up.
      </p>
      <br />
      <div className="columns is-desktop is-variable is-8 m-t-md">
        <div className="column is-4 flex-column-centered m-b-lg">
          <i
            className="fas fa-envelope is-size-1 m-b-lg has-text-grey-dark"
            style={{
              fontSize: "4rem",
            }}
          ></i>
          <p className="has-text-justified">
            <b>1.</b> Email your friends, family and your network to see who
            already has access to OCCASIO. They'll be able to invite you.
          </p>
        </div>
        <div className="column is-4 flex-column-centered m-b-lg">
          <span className="flex-row-centered">
            <a
              className="fab fa-facebook-f is-size-1 m-b-lg has-text-grey-dark"
              style={{
                fontSize: "4rem",
              }}
              target="_blank"
              href="https://www.facebook.com/onOCCASIO/"
            ></a>
            <a
              className="fab fa-twitter is-size-1 m-b-lg m-l-lg has-text-grey-dark"
              style={{
                fontSize: "4rem",
              }}
              target="_blank"
              href="https://twitter.com/onoccasio"
            ></a>
            <a
              className="fab fa-instagram is-size-1 m-b-lg m-l-lg has-text-grey-dark"
              style={{
                fontSize: "4rem",
              }}
              target="_blank"
              href="https://www.instagram.com/onoccasio"
            ></a>
            <a
              className="fab fa-linkedin-in is-size-1 m-b-lg m-l-lg has-text-grey-dark"
              style={{
                fontSize: "4rem",
              }}
              target="_blank"
              href="https://www.linkedin.com/company/15820548/"
            ></a>
          </span>
          <p className="has-text-justified">
            <b>2.</b> Follow our social media feeds on{" "}
            <a
              title="facebook"
              target="_blank"
              href="https://www.facebook.com/onOCCASIO/"
            >
              Facebook
            </a>
            {", "}
            <a
              title="twitter"
              target="_blank"
              href="https://twitter.com/onoccasio/"
            >
              Twitter
            </a>
            {", "}
            <a
              title="twitter"
              target="_blank"
              href="https://www.instagram.com/onoccasio/"
            >
              Instagram
            </a>
            {" and "}
            <a
              title="linkedin"
              target="_blank"
              href="https://www.linkedin.com/company/15820548/"
            >
              LinkedIn
            </a>{" "}
            to see invitation links when they are shared periodically.
          </p>
        </div>
        <div className="column is-4 flex-column-centered m-b-lg">
          <i
            className="fas fa-users is-size-1 m-b-lg has-text-grey-dark"
            style={{
              fontSize: "4rem",
            }}
          ></i>
          <p className="has-text-justified">
            <b>3.</b> Post a personalised message on social media on any of the
            platforms to attract and get invited by members of the community.
          </p>
          <div className=" social">
            <a
              title="facebook"
              target="_blank"
              // 500 chars limit on quote
              href={`https://www.facebook.com/sharer/sharer.php?u=${
                location.origin
              }${withPrefix("/")}&hashtag=${convertHashtagsToURLFormat(
                ["onOCCASIO"],
                true,
              )}&quote=${ASK_FOR_INVITE_TEXT}`}
            >
              <span
                className="fab fa-facebook-f"
                alt="facebook"
                style={{ width: "1em", height: "1em" }}
              />
            </a>
            <a
              title="twitter"
              target="_blank"
              href={`http://twitter.com/intent/tweet?text=${ASK_FOR_INVITE_TEXT}&hashtags=${convertHashtagsToURLFormat(
                ["onOCCASIO", "onOCCASIOInvite", "onOCCASIOReferral"],
              )}`}
            >
              <span
                className="fab fa-twitter"
                alt="twitter"
                style={{ width: "1em", height: "1em" }}
              />
            </a>
            <a
              title="whatsapp"
              target="_blank"
              href={`https://api.whatsapp.com/send?text=${ASK_FOR_INVITE_TEXT_W_HASHTAGS}`}
            >
              <span
                className="fab fa-whatsapp"
                alt="whatsapp"
                style={{ width: "1em", height: "1em" }}
              />
            </a>
            <a
              title="show text"
              className="is-tooltip-bottom is-tooltip-multiline"
              data-tooltip={
                "Following text copied: " + ASK_FOR_INVITE_TEXT_W_HASHTAGS
              }
              id="share-text-btn"
              onClick={() => {
                copyToClipboard(ASK_FOR_INVITE_TEXT_W_HASHTAGS);
                const urlDiv = document.getElementById("share-text-btn");
                urlDiv.classList.add("tooltip", "is-tooltip-active");
                setTimeout(() => {
                  urlDiv.classList.remove("tooltip", "is-tooltip-active");
                }, 10000);
              }}
            >
              <span
                className="far fa-copy"
                alt="show text"
                style={{ width: "1em", height: "1em" }}
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

const GetInvited = ({ location }) => {
  return <PlainPage Content={Content} location={location} />;
};

export default GetInvited;
